<template>
  <div class="showMeetTime">
    <div class="cell">
      <van-cell
        center
        size="32"
        title-style="fontSize:16px;"
        :title="$t('seeting.showTime')"
      >
        <template #right-icon>
          <van-switch
            v-model="showDuration"
            :active-value="1"
            :inactive-value="0"
            @change="onCheckedNormalSetting"
            size="24"
          />
        </template>
      </van-cell>
    </div>
    <div class="info">{{ $t("seeting.showYourTime") }}</div>

    <div class="button" @click="back">
      <van-button size="large" class="btn bottomBtn" type="default">{{
        $t("common.back")
      }}</van-button>
    </div>
  </div>
</template>

<script>
import { getLocalDeviceInfo, setLocalDeviceInfo } from "@/utils/device";
export default {
  data() {
    return {
      passwordChecked: true,
      meetChecked: true,
      muteChecked: true,
      showDuration: 0,
    };
  },
  mounted() {
    this.loadNormalSetting();
  },
  methods: {
    meetSetting() {
      this.$router.push("meetSetting");
    },
    go(path) {
      this.$router.push(path);
    },
    //常规设置
    loadNormalSetting() {
      const localDeviceData = getLocalDeviceInfo();
      //显示我的参会时长 首次默认选中
      const defaultMark = localDeviceData.joinDurationTag;
      if (!defaultMark && !localDeviceData.joinDuration) {
        this.showDuration = 1;
        setLocalDeviceInfo({
          joinDurationTag: 1,
          joinDuration: 1,
        });
        this.$store.commit("meet/updateGlobalMeetState", {
          joinDuration: 1,
        });
      } else if (localDeviceData.joinDuration > 0) {
        this.showDuration = 1;
      }
    },
    onCheckedNormalSetting() {
      setLocalDeviceInfo({
        joinDurationTag: 1,
        joinDuration: this.showDuration ,
      });
      this.$store.commit("meet/updateGlobalMeetState", {
        joinDuration: this.showDuration
      });
    },

    back() {
      this.$router.go(-1);
    },
  },
};
</script>
<style scoped lang='less'>
.showMeetTime {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #fafafa;
  .cell {
    margin-top: 16px;
  }
  .button {
    width: 100%;
    box-sizing: border-box;
    padding: 0 64px;
    margin-top: 80px;
    .btn {
      height: 88px;
    }
    .bottomBtn {
      margin-top: 32px;
    }
  }

  .info {
    width: 100%;
    height: 64px;
    font-size: 24px;
    color: #999999;
    line-height: 64px;
    padding-left: 40px;
    box-sizing: border-box;
  }
}
</style>
